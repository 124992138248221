<div class="table-flows">
  <div class="container-fluid">
    <div id="main-table">
      <!-- Table header start -->
      <div class="table-head-container">
        <div class="row">
          <div class="col-12">
            <div class="table-container">
              <div class="table-cell-container">
                <div class="title-container">
                  <h4 class="title">תהליכים</h4>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- Table header end -->
      <div class="loading-state" *ngIf="isLoading">
        <app-loading></app-loading>
      </div>
      <!-- Main Table  -->
      <kendo-grid
        *ngIf="!isLoading"
        class="k-rtl"
        [data]="gridData"
        [style.height.%]="100"
        [pageSize]="GridState.take"
        [skip]="GridState.skip"
        [sort]="GridState.sort"
        [filter]="GridState.filter"
        [sortable]="true"
        filterable="menu"
        (dataStateChange)="DataStateChange($event)"
      >
        <!-- Company Name -->
        <kendo-grid-column
          class="text-center Center"
          field="{{ gridColumn[0].field }}"
          width="{{ gridColumn[0].width }}"
          [headerStyle]="{ 'font-weight': 'bold', 'padding-right': '12px' }"
          title="{{ gridColumn[0].title }}"
          [filterable]="true"
        >
          <ng-template
            kendoGridFilterMenuTemplate
            let-column="column"
            let-filter="filter"
            let-filterService="filterService"
          >
            <kendo-grid-string-filter-menu
              [column]="column"
              [filter]="filter"
              [filterService]="filterService"
              [extra]="false"
            >
            </kendo-grid-string-filter-menu>
          </ng-template>
          <ng-template kendoGridCellTemplate let-dataItem>
            <a class="text-primary pointer" (click)="enterToFlow(dataItem.id, dataItem.stage.id)">{{
              dataItem?.company?.nameHebrew
            }}</a>
            <span *ngIf="dataItem.isAwaitingDeletion" class="title-tag">ממתין לאישור מחיקה</span>
            <span
              *ngIf="dataItem.assignedUser.id === this._currentUserID && dataItem.stage.id !== FlowStage.FINISHED"
              class="title-tag"
              >ממתין לטיפולך</span
            >
          </ng-template>
        </kendo-grid-column>
        <!-- Company ID -->
        <kendo-grid-column
          class="text-center Center"
          field="{{ gridColumn[1].field }}"
          width="{{ gridColumn[1].width }}"
          [headerStyle]="{ 'font-weight': 'bold', 'padding-right': '12px' }"
          title="{{ gridColumn[1].title }}"
          [filterable]="true"
        >
          <ng-template
            kendoGridFilterMenuTemplate
            let-column="column"
            let-filter="filter"
            let-filterService="filterService"
          >
            <kendo-grid-string-filter-menu
              [column]="column"
              [filter]="filter"
              [filterService]="filterService"
              [extra]="false"
            >
            </kendo-grid-string-filter-menu>
          </ng-template>
        </kendo-grid-column>
        <!-- Type Name -->
        <kendo-grid-column
          class="text-center Center"
          field="{{ gridColumn[2].field }}"
          width="{{ gridColumn[2].width }}"
          [headerStyle]="{ 'font-weight': 'bold', 'padding-right': '12px' }"
          title="{{ gridColumn[2].title }}"
          [filterable]="true"
        >
          <ng-template
            kendoGridFilterMenuTemplate
            let-column="column"
            let-filter="filter"
            let-filterService="filterService"
          >
            <kendo-grid-string-filter-menu
              [column]="column"
              [filter]="filter"
              [filterService]="filterService"
              [extra]="false"
            >
            </kendo-grid-string-filter-menu>
          </ng-template>
        </kendo-grid-column>
        <!-- Year change to dropdown-->
        <kendo-grid-column
          class="text-center Center"
          field="{{ gridColumn[3].field }}"
          width="{{ gridColumn[3].width }}"
          [headerStyle]="{ 'font-weight': 'bold', 'padding-right': '12px' }"
          title="{{ gridColumn[3].title }}"
          [filterable]="true"
        >
          <ng-template
            kendoGridFilterMenuTemplate
            let-column="column"
            let-filter="filter"
            let-filterService="filterService"
          >
            <kendo-combobox
              [data]="yearOptions"
              [valuePrimitive]="true"
              [value]="yearValue"
              placeholder="בחר שנה..."
              (valueChange)="yearFilterChange($event, filter)"
            >
            </kendo-combobox>
          </ng-template>
        </kendo-grid-column>
        <!-- Dead Line -->
        <kendo-grid-column
          class="text-center Center"
          field="{{ gridColumn[4].field }}"
          width="{{ gridColumn[4].width }}"
          [headerStyle]="{ 'font-weight': 'bold', 'padding-right': '12px' }"
          title="{{ gridColumn[4].title }}"
          [filterable]="true"
        >
          <ng-template kendoGridFilterMenuTemplate let-filter let-column="column" let-filterService="filterService">
            <kendo-grid-date-filter-menu
              [column]="column"
              [filter]="filter"
              [filterService]="filterService"
              operator="eq"
              [extra]="false"
              format="dd MMM, yyyy"
            >
              <kendo-filter-eq-operator></kendo-filter-eq-operator>
            </kendo-grid-date-filter-menu>
          </ng-template>
          <ng-template kendoGridCellTemplate let-dataItem="dataItem">
            <p [class.deadline-red]="!this.isDue(dataItem) && !dataItem.isDone" *ngIf="!dataItem.isDone">
              {{ dataItem.deadline | parseDatePipe }}
            </p>
            <p *ngIf="dataItem.isDone">תהליך הסתיים</p>
          </ng-template>
        </kendo-grid-column>
        <!-- Stage Name -->
        <kendo-grid-column
          class="text-center Center"
          field="{{ gridColumn[5].field }}"
          width="{{ gridColumn[5].width }}"
          [headerStyle]="{ 'font-weight': 'bold', 'padding-right': '12px' }"
          title="{{ gridColumn[5].title }}"
          [filterable]="true"
        >
          <ng-template
            kendoGridFilterMenuTemplate
            let-column="column"
            let-filter="filter"
            let-filterService="filterService"
          >
            <kendo-combobox
              [data]="stageNameOptions"
              [valuePrimitive]="true"
              [value]="stageValue"
              textField="stageName"
              valueField="stageName"
              placeholder="בחר שלב בתהליך..."
              (valueChange)="stageNameFilterChange($event, filter)"
            >
            </kendo-combobox>
          </ng-template>
        </kendo-grid-column>
        <!-- Risk Level -->
        <kendo-grid-column
          class="text-center Center"
          field="{{ gridColumn[6].field }}"
          width="{{ gridColumn[6].width }}"
          [headerStyle]="{ 'font-weight': 'bold', 'padding-right': '12px' }"
          title="{{ gridColumn[6].title }}"
          [filterable]="true"
        >
          <ng-template
            kendoGridFilterMenuTemplate
            let-column="column"
            let-filter="filter"
            let-filterService="filterService"
          >
            <kendo-combobox
              [data]="riskOptions"
              [valuePrimitive]="true"
              [value]="riskValue"
              textField="riskName"
              valueField="riskValue"
              placeholder="בחר רמת סיכון..."
              (valueChange)="riskFilterChange($event, filter)"
            >
            </kendo-combobox>
          </ng-template>
          <ng-template kendoGridCellTemplate let-dataItem="dataItem">
            {{ translateRiskLevel(dataItem.riskLevel) }}
          </ng-template>
        </kendo-grid-column>
        <!-- Manager -->
        <kendo-grid-column
          class="text-center Center"
          field="{{ gridColumn[7].field }}"
          width="{{ gridColumn[7].width }}"
          [headerStyle]="{ 'font-weight': 'bold', 'padding-right': '12px' }"
          title="{{ gridColumn[7].title }}"
          [filterable]="true"
        >
          <ng-template
            kendoGridFilterMenuTemplate
            let-column="column"
            let-filter="filter"
            let-filterService="filterService"
          >
            <kendo-grid-string-filter-menu
              [column]="column"
              [filter]="filter"
              [filterService]="filterService"
              [extra]="false"
            >
            </kendo-grid-string-filter-menu>
          </ng-template>
        </kendo-grid-column>
        <!-- Last Updated By -->
        <kendo-grid-column
          class="text-center Center"
          field="{{ gridColumn[8].field }}"
          width="{{ gridColumn[8].width }}"
          [headerStyle]="{ 'font-weight': 'bold', 'padding-right': '12px' }"
          title="{{ gridColumn[8].title }}"
          [filterable]="true"
        >
          <ng-template
            kendoGridFilterMenuTemplate
            let-column="column"
            let-filter="filter"
            let-filterService="filterService"
          >
            <kendo-grid-string-filter-menu
              [column]="column"
              [filter]="filter"
              [filterService]="filterService"
              [extra]="false"
            >
            </kendo-grid-string-filter-menu>
          </ng-template>
        </kendo-grid-column>
        <!-- Text For No Rows  -->
        <ng-container *ngIf="!isLoading && !gridData">
          <ng-template kendoGridNoRecordsTemplate>
            <p class="text">לא קיימים תהליכים פעילים עבורך.</p>
            <div class="text">ניתן ללחוץ על ״יצירת תהליך חדש״ ולבחור את סוג התהליך שברצונך לפתוח</div>
          </ng-template>
        </ng-container>
        <!-- Change Caption For Filter-->
        <kendo-grid-messages
          filterContainsOperator="מכיל..."
          filterEndsWithOperator="נגמר עם..."
          filterEqOperator="שווה ל..."
          filterNotEqOperator="לא שווה ל..."
          filterNotContainsOperator="לא מכיל..."
          filterStartsWithOperator="מתחיל ב..."
          filterIsNullOperator="ללא נתון"
          filterIsNotNullOperator="מכיל נתון כלשהו"
          filterIsEmptyOperator="ריק"
          filterIsNotEmptyOperator="לא ריק"
          filterGteOperator="גדול או שווה ל..."
          noRecords="לא קיימים תהליכים פעילים עבורך"
          filterFilterButton="סנן"
          filterClearButton="נקה בחירה"
        >
        </kendo-grid-messages>
      </kendo-grid>
      <!-- Main Table  End-->
      <div class="table-pagination d-flex justify-content-between p-2">
        <ng-container *ngIf="data.flows">
          <ngb-pagination [collectionSize]="data.total" [pageSize]="data.limit" (pageChange)="pageChange($event)">
            <ng-template ngbPaginationPrevious>
              <app-svg-icon name="angle-right_special"></app-svg-icon>
              לעמוד קודם
            </ng-template>
            <ng-template ngbPaginationNext>
              לעמוד הבא
              <app-svg-icon name="angle-left_special"></app-svg-icon>
            </ng-template>
          </ngb-pagination>
        </ng-container>
      </div>
    </div>
  </div>
</div>
