import { Component, OnInit, Input, Output, EventEmitter, ViewChildren, QueryList, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DigitalitRoutes } from '@digital/app/_constants';

import { Flows } from '../../../_models/flows.model';

import gridFieldsJson from '../../../../assets/data/gridFields.json';

import { SortableHeaderDirective } from '@libs/directives/sortable-header.directive';
import { AuthenticationService } from '@libs/authentication/authentication.service';
import { FlowService } from '@digital/app/core/services/flow.service';
import { Flow, RiskLevelType } from '@digital/app/_models/flow.model';
import { FlowStage } from '@digital/app/_enums/flow-stages.enum';

import { State, process } from '@progress/kendo-data-query';
import { DataStateChangeEvent, GridDataResult } from '@progress/kendo-angular-grid';
import { ParseDatePipe } from '@libs/core/pipes/parse-date.pipe';
import { GridData } from '@digital/app/_models/grid-data';
import { FlowsFacade } from '../../flows.facade';
import { UserRole } from '@libs/authentication/models/user.model';
import { ErrorDialogComponent } from '@libs/views/error-dialog/error-dialog.component';
import { FilterButton } from '../../models/filter-button.model';
import { SortOption } from '../../models/sort-option.model';

@Component({
  selector: 'app-table-flows',
  templateUrl: './table-flows.component.html',
  styleUrls: ['./table-flows.component.scss']
})
export class TableFlowsComponent implements OnInit {
  @ViewChildren(SortableHeaderDirective) headers: QueryList<SortableHeaderDirective>;
  private _activeFilterButton?: FilterButton;
  _currentUserID: any;
  gridData: GridDataResult;
  gridColumn: GridData[];
  filterdFlows: Array<Flow>;
  riskValue: RiskLevelType;
  stageValue: number;
  yearValue: number;

  @Input() isLoading = false;
  @Input() data: Flows = undefined;
  @Input() searchValue: string;
  @Output() pageChanged = new EventEmitter();
  @Output() filterButtonClicked = new EventEmitter();
  @Output() sortButtonClicked = new EventEmitter<{ column: string, direction: string }>();
  
  GridState: State = { skip: 0, take: 2000 };
  riskOptions = [
    { riskName: this.translateRiskLevel(RiskLevelType.High), riskValue: RiskLevelType.High },
    { riskName: this.translateRiskLevel(RiskLevelType.Medium), riskValue: RiskLevelType.Medium },
    { riskName: this.translateRiskLevel(RiskLevelType.Low), riskValue: RiskLevelType.Low },
    { riskName: this.translateRiskLevel(RiskLevelType.Undefined), riskValue: RiskLevelType.Undefined }
  ];
  stageNameOptions = this._flowService.getStagesType();
  yearOptions = {};

  private currentUser = this._authenticationService.getCurrentUser();

  // Lifecyle
  constructor(
    private _authenticationService: AuthenticationService,
    private _datePipe: ParseDatePipe,
    private _flowService: FlowService,
    private _flowFacade: FlowsFacade,
    private _router: Router,
    private modalService: NgbModal,
    private _activeModal: NgbActiveModal
  ) {}

  ngOnInit() {
    this._currentUserID = this._authenticationService.getCurrentUser().id;
    this.gridColumn = gridFieldsJson.mainGridFields;
    if (this.data.flows !== undefined) {
      this._flowFacade.getAllFlows(1);
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.data.flows !== undefined) {
      this.sortGridData();
      this.gridData = process(this.data.flows, this.GridState);
      this.filterdFlows = this.data.flows;
      this.yearOptions = [...new Set(this.data.flows.map((flow) => flow.year))];
    }
  }

  pageChange(page: number) {
    this.pageChanged.emit(page);
  }

  didClickFilterButton(button: FilterButton) {
    // this._setFilterButtonState(button);
    if (this._activeFilterButton === button) {
      this.filterButtonClicked.emit(button);
    } else {
      this.filterButtonClicked.emit(undefined);
    }
  }

  didClickSortColumnButton(sortButton: Element, sortByColumn: string, sortOption: SortOption) {
    const currentButtonSortDirection = sortButton.getAttribute('data-sort-direction');
    if (currentButtonSortDirection !== sortOption.direction) {
      sortButton.setAttribute('data-sort-direction', sortOption.direction);
      const className = sortOption.direction === 'DESC' ? sortOption.classDescending : sortOption.classAscending;
      const svg = sortButton.querySelector('app-svg-icon');
      svg.attributes.getNamedItem('name');
    }

    this.sortButtonClicked.emit({ column: sortByColumn, direction: sortOption.direction });
  }

  // Getters
  get flowRoute() {
    return '../' + DigitalitRoutes.flow.main;
  }

  public get FlowStage(): typeof FlowStage {
    return FlowStage;
  }

  get isExternalUser() {
    return this.currentUser?.role === UserRole.EXTERNAL;
  }

  // Actions

  sortGridData() {
    this.data.flows.sort((a, b) => b.year - a.year || a.stage.id - b.stage.id);
  }

  yearFilterChange(event, filter: any) {
    filter.filters = [
      {
        field: 'year',
        operator: 'eq',
        value: event
      }
    ];

    this.yearValue = event;
  }

  stageNameFilterChange(event, filter: any) {
    filter.filters = [
      {
        field: 'stage.name',
        operator: 'contains',
        value: event
      }
    ];

    this.stageValue = event;
  }

  riskFilterChange(event, filter: any) {
    filter.filters = [
      {
        field: 'riskLevel',
        operator: 'contains',
        value: event
      }
    ];

    this.riskValue = event;
  }

  isDue(flow: Flow): boolean {
    if (flow.isDone) {
      return false;
    }
    return new Date(flow.deadline).getTime() > new Date().getTime();
  }

  translateRiskLevel(riskLevel) {
    switch (riskLevel) {
      case RiskLevelType.Undefined:
        return 'לא מוגדר';
      case RiskLevelType.Low:
        return 'נמוך';
      case RiskLevelType.Medium:
        return 'בינוני';
      case RiskLevelType.High:
        return 'גבוה';
      default:
        return '';
    }
  }

  openModalForExternalUserNotValid() {
    const modalRef = this.modalService.open(ErrorDialogComponent, {
      windowClass: 'confirmation-modal',
      centered: true
    });
    modalRef.componentInstance.title = 'שגיאה';
    modalRef.componentInstance.body = 'התהליך נמצא בעבודת צוות הביקורת';
  }

  enterToFlow(flowID: number, stageID: number) {
    if (this.isExternalUser) {
      if (stageID !== FlowStage.WORK_ON_TAX_FLOW && stageID !== FlowStage.CLIENT_SIGNATURE) {
        this.openModalForExternalUserNotValid();
      } else {
        this._router.navigate([this.flowRoute, flowID]);
      }
    } else {
      this._router.navigate([this.flowRoute, flowID]);
    }
  }

  public DataStateChange(state: DataStateChangeEvent): void {
    let filtersArr;
    let filters;

    if (state.filter) {
      filtersArr = state.filter.filters;

      if (filtersArr.length > 0) {
        filtersArr.forEach((filter) => {
          filters = filter['filters'];
          let isExistsRiskLevelFilter = filters.some((filter) => {
            return filter.field === 'riskLevel' && filter.value === this.riskValue;
          });
          let isExistsStageNameFilter = filters.some((filter) => {
            return filter.field === 'stage.name' && filter.value === this.stageValue;
          });
          let isExistsYearFilter = filters.some((filter) => {
            return filter.field === 'year' && filter.value === this.yearValue;
          });
          if (!isExistsRiskLevelFilter) {
            this.riskValue = undefined;
          }
          if (!isExistsStageNameFilter) {
            this.stageValue = undefined;
          }
          if (!isExistsYearFilter) {
            this.yearValue = undefined;
          }

          if (filters[0]['field'] == 'deadline' && filters[0]['operator'] == 'eq') {
            this.filterdFlows = this.filterdFlows.filter((p) =>
              this._datePipe.transform(p.deadline).includes(this._datePipe.transform(filters[0]['value']))
            );
            if (this.filterdFlows.length !== 0) {
              filters[0]['value'] = this.filterdFlows[0].deadline;
            }
            this.GridState = state;
            this.gridData = process(this.data.flows, this.GridState);
            filters[0]['value'] = new Date(filters[0]['value']);
          } else if (filters[0]['field'] == 'riskLevel') {
            this.filterdFlows = this.filterdFlows.filter((p) =>
              this.translateRiskLevel(p.riskLevel).includes(filters[0]['value'])
            );
            if (this.filterdFlows.length !== 0) {
              filters[0]['value'] = this.filterdFlows[0].riskLevel;
            }
            this.GridState = state;
            this.gridData = process(this.data.flows, this.GridState);
          } else if (filters[0]['field'] !== 'deadline') {
            this.GridState = state;
            if (this.GridState.filter.filters[0]['filters'][0]['field'] == 'deadline') {
              this.GridState.filter.filters[0]['filters'][0]['value'] = this.filterdFlows[0].deadline;
              this.gridData = process(this.data.flows, this.GridState);
              this.GridState.filter.filters[0]['filters'][0]['value'] = new Date(
                this.GridState.filter.filters[0]['filters'][0]['value']
              );
            } else this.gridData = process(this.data.flows, this.GridState);
          }
        });
      } else {
        this.GridState = state;
        this.gridData = process(this.data.flows, this.GridState);
        this.riskValue = undefined;
        this.stageValue = undefined;
        this.yearValue = undefined;
      }
    } else {
      this.GridState = state;
      this.gridData = process(this.data.flows, this.GridState);
      this.riskValue = undefined;
      this.stageValue = undefined;
      this.yearValue = undefined;
    }
  }

  closeModal() {
    this._activeModal.close();
  }
}
