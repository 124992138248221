import { Injectable, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { resetStores, akitaConfig } from '@datorama/akita';
import { AuthenticationStore } from './state/authentication.store';
import { AuthenticationQuery } from './state/authentication.query';

import { catchError, switchMap, tap } from 'rxjs/operators';
import { BehaviorSubject, Observable, of, throwError } from 'rxjs';

import { Environment } from '../environment';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SessionExpiredModalComponent } from './components/session-expired-modal/session-expired-modal.component';

import { AuthenticationAPI } from './apis/authentication.api';
import { User } from './models/user.model';
import { environment } from '@digital/environments/environment';
import { GlobalRoutes } from '@libs/constants';
import { HttpErrorResponse } from '@angular/common/http';
import { MsalAuthService } from './msal/auth.service';
import { AuthenticationResult } from '@azure/msal-browser';
import { ToastrService } from 'ngx-toastr';

@Injectable({ providedIn: 'root' })
export class AuthenticationService implements OnDestroy {
  isLoggedIn$ = this._authenticationQuery.isLoggedIn$;
  accessToken$ = this._authenticationQuery.accessToken$;
  errorMessage: string = '';

  private _environment: Environment = environment;

  private _isUserInactive$: BehaviorSubject<boolean> = new BehaviorSubject(false);

  private _userSessionTimeout: ReturnType<typeof setTimeout>;
  private _tokenRefresher: ReturnType<typeof setInterval>;

  private _mouseMoveEvent: (ev: MouseEvent) => void;

  /* #region Lifecycle  */

  constructor(
    private _authenticationStore: AuthenticationStore,
    private _authenticationQuery: AuthenticationQuery,
    private _authenticationAPI: AuthenticationAPI,
    private _router: Router,
    private _modalService: NgbModal,
    private _msalAuthService: MsalAuthService,
    private toastr: ToastrService
  ) {
    //this._attachUserActivityEvent();
    akitaConfig({ resettable: true });
    this.msalHandelRedirects();
    this.toastr.toastrConfig.preventDuplicates = true;
  }

  ngOnDestroy() {
    this._detachUserActivityEvent();
  }

  initWithEnvironment(environment: Environment): AuthenticationService {
    this._environment = environment;
    return this;
  }

  /* #endregion */

  /* #region Public methods  */

  logoutRefreshTokenExpired() {
    this.routeUserByDivision();
    const modalRef = this._modalService.open(SessionExpiredModalComponent, {
      windowClass: 'session-expired-modal',
      centered: true,
      backdrop: 'static'
    });
    modalRef.result.then(() => {
      resetStores();
      this._msalAuthService.forceSignOut();
      this.routeUserByDivision();
      this._msalAuthService.signIn();
    });
  }

  isLoggedIn(): boolean {
    return this._authenticationQuery.isLoggedIn();
  }

  isAuthenticated(): boolean {
    return this._authenticationQuery.isAuthenticated();
  }

  getCurrentUser(): User {
    if (!this.isLoggedIn()) {
      return null;
    }
    return this._authenticationQuery.getCurrentUser();
  }
  /* #endregion */

  /* #region Authentication  */
  async authenticate(data?, sessionExpired?): Promise<any> {
    return new Promise((resolve, reject) => {
      if (data.internalUser || data.externalUser) {
        const sendData = data.internalUser?.userName ? data.internalUser : data.externalUser;
        this._authenticationAPI
          .login(sendData)
          .pipe(
            tap({
              next: (user) => {
                user.isAuthenticated = true;
                this._authenticationStore.setLoading(true);
                this._authenticationStore.update(user);
                this._authenticationStore.setLoading(false);
                this.routeUserByDivision();
                if (!environment.production) {
                  console.log('Token created:', user.accessToken);
                }
                resolve(user);
              },
              error: (err) => {
                reject(err);
              }
            }),
            catchError((error: HttpErrorResponse): Observable<any> => {
              if (error.status === 404) {
                return of(null);
              }
              resetStores();
              return throwError(error);
            })
          )
          .subscribe();
      } else {
        reject('No data');
      }
    });
  }

  refreshToken(): Observable<User> {
    const currentUser = this.getCurrentUser();
    const token = currentUser.refreshToken;
    const userName = currentUser.username;

    return this._authenticationAPI.refreshToken(userName, token).pipe(
      tap((setUser) => {
        this._authenticationStore.setLoading(true);
        currentUser.accessToken = setUser.accessToken;
        currentUser.isAuthenticated = true;
        this._authenticationStore.update(currentUser);
        this._authenticationStore.setLoading(false);
      }),
      switchMap((user) => {
        if (!environment.production) {
          console.log('Token refreshed:', user.accessToken);
        }
        return of(user);
      })
    );
  }

  /* #endregion */

  /* #region Token refresher  */

  private _startTokenRefresher() {
    this._clearTokenRefresher();
    this._tokenRefresher = setInterval(() => this.refreshToken(), this._environment.userSessionDuration);
  }

  private _clearTokenRefresher() {
    clearInterval(this._tokenRefresher);
    this._tokenRefresher = undefined;
  }

  /* #endregion */

  /* #region User activity listener */

  private _detectUserSessionActivityIfNeeded(sessionExpired?: () => void) {
    if (this._environment.shouldDetectUserSessionActivity) {
      this._isUserInactive$.subscribe((isInactive) => {
        if (isInactive) {
          this._clearTokenRefresher();
          this._openSessionExpiredModal();
          if (sessionExpired) {
            sessionExpired();
          }
          return;
        }
        this._startTokenRefresher();
      });
    } else {
      this._startTokenRefresher();
    }
  }

  private _startUserSessionTimeout() {
    this._userSessionTimeout = setTimeout(
      () => this._isUserInactive$.next(true),
      this._environment.userSessionDuration
    );
  }

  private _clearUserSessionTimeout() {
    clearTimeout(this._userSessionTimeout);
    this._userSessionTimeout = undefined;
  }

  private _attachUserActivityEvent() {
    this._mouseMoveEvent = (ev: MouseEvent) => {
      if (!this._isUserInactive$.value && this.isLoggedIn()) {
        this._clearUserSessionTimeout();
        this._startUserSessionTimeout();
      }
    };
    window.addEventListener('mousemove', this._mouseMoveEvent);

    if (this.isLoggedIn()) {
      this._detectUserSessionActivityIfNeeded();
    }
  }

  private _detachUserActivityEvent() {
    window.removeEventListener('mousemove', this._mouseMoveEvent);
  }

  /* #endregion */

  /* #region Session expiration modal */

  private _openSessionExpiredModal() {
    //this._clearTokenRefresher();
    this._clearUserSessionTimeout();
    //this._detachUserActivityEvent();

    this._authenticationStore.setLoading(true);
    this._authenticationStore.update({ isAuthenticated: false });
    this._authenticationStore.setLoading(false);

    const modalRef = this._modalService.open(SessionExpiredModalComponent, {
      windowClass: 'session-expired-modal',
      centered: true,
      backdrop: 'static'
    });
    modalRef.result.then(() => {
      this._startUserSessionTimeout();
    });
  }

  /* #endregion */

  /* #region Msal */

  msalHandelRedirects() {
    this._msalAuthService.handleRedirects().subscribe({
      next: async (result: AuthenticationResult) => {
        if (!this._msalAuthService.getActiveAccount() && this._msalAuthService.getAllAccounts().length > 0) {
          const isExternalUser = result.account.name.includes('External');
          if (isExternalUser) {
            const email = result.account.idTokenClaims.email;
            if (email) {
              const data = { externalUser: { email, accessToken: result.accessToken  } };
              try {
                const user = await this.authenticate(data);
                this.setIsDeloitteUser(true);
              } catch (error) {
                throw new Error('User not Authenticated');
              }
            } else {
              throw new Error('Email not found');
            }
          } else {
            // const userName = result.account.username.substring(0, result.account.username.indexOf('@'));
            const data = { internalUser: { userName: result.account.username, accessToken: result.accessToken } };
            try {
              const user = await this.authenticate(data);
              this.setIsDeloitteUser(true);
            } catch (error) {
              throw new Error('User not Authenticated');
            }
          }
        }
      },
      error: (error) => console.log(error)
    });
  }

  signInMsal() {
    return this._msalAuthService.signIn();
  }

  async signOutMsal() {
    await this._msalAuthService.signOut();
    resetStores();
  }

  getIsDeloitteUser() {
    return this._msalAuthService.authenticated;
  }

  setIsDeloitteUser(value: boolean) {
    this._msalAuthService.authenticated = value;
  }

  routeUserByDivision() {
    const currentUser = this.getCurrentUser();
    if (currentUser.division === 'audit' || currentUser.role === 'external') {
      this._router.navigate([GlobalRoutes.dtax]);
    } else if (currentUser.division === 'tax') {
      this._router.navigate([GlobalRoutes.qtax.base]);
    } else if (currentUser.division === 'all') {
      if (this._router.url.includes('qtax') || this._router.url.includes('query')) {
        this._router.navigate([GlobalRoutes.qtax.base]);
      }
      else {
        this._router.navigate([GlobalRoutes.dtax]);
      }
    }
  }

  /* #endregion */
}
