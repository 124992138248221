import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ID } from '@datorama/akita';
import { FlowService } from '@digital/app/core/services/flow.service';
import { Scenario, Stage } from '@digital/app/flow/models';
import { Section } from '@digital/app/flow/models/section.model';
import { Files } from '@digital/app/flow/models/sections-files.model';
import { DigitalitRoutes } from '@digital/app/_constants';
import { FlowStage } from '@digital/app/_enums/flow-stages.enum';
import { IFlowEditingPermissions } from '@digital/app/_interfaces/flow-editing-permissions.interface';
import { Flow } from '@digital/app/_models/flow.model';
import { AuthenticationService } from '@libs/authentication/authentication.service';
import { UserRole } from '@libs/authentication/models/user.model';

@Component({
  selector: 'app-flow-edit-mode',
  templateUrl: './flow-edit-mode.component.html',
  styleUrls: ['./flow-edit-mode.component.scss']
})
export class FlowEditModeComponent implements OnInit, OnChanges {
  @Input() stageID: number;
  @Input() stage: any;
  @Input() flow: Flow;
  @Input() flowID: number;
  @Input() currentUserEditor: number;
  @Input() scenario: Scenario;
  @Input() isLoading: boolean;
  @Input() editingPermissions: IFlowEditingPermissions;

  private currentUser = this.authService.getCurrentUser();

  currentUserID: ID;
  sectionsUntilQuality: Section[];
  sectionsFromQuality: Section[];
  isConsultation: Stage;
  sectionFiles: Files[];
  isUploaderHasFile: boolean;
  digitalFormsRoute: string;
  hasDigitalForms = false;
  isAllSignaturesApproved = false;
  isEnteredSignDate = false;

  FlowStage = FlowStage;

  get canShowDigitalFormsEditButton(): boolean {
    return this.hasDigitalForms && !this.editingPermissions.isLockedForEditing;
  }

  get isAdmin() {
    return this.currentUser.role === UserRole.ADMIN;
  }

  get isFormEditor() {
    return this.currentUser.role === UserRole.FORMEDITOR;
  }

  get isExternalUser() {
    return this.currentUser.role === UserRole.EXTERNAL;
  }

  constructor(private authService: AuthenticationService, public flowService: FlowService, private router: Router) {}

  ngOnInit() {
    this.fetchFlowsSections();
    this.currentUserID = this.currentUser.id;
    this.digitalFormsRoute = `${this.router.url}/${DigitalitRoutes.flow.digitalForms}`;
  }

  ngOnChanges() {
    this.flowService.isChangingStage = false;
    if (this.scenario) {
      const filterStages = this.scenario.stages.filter((stage) => {
        return stage.id === this.flow.stage.id;
      });
      this.isConsultation = filterStages.find((user) => {
        if (user.consult) {
          return user.consult.consultationUserID;
        }
      });
    }
  }

  fetchFlowsSections() {
    this.flowService.getFlowSections(this.flowID, (sections: Section[]) => {
      this.sectionsUntilQuality = sections.filter((item) => item.id < 6);
      this.sectionsFromQuality = sections.filter((item) => item.id >= 6);
    });
  }

  uploaderFileUpdated($event) {
    this.isUploaderHasFile = $event;
  }

  showFlowSectionTable() {
    if (
      this.stage?.id !== FlowStage.SIGNATURE_IMPLEMENTATION &&
      this.stage?.id !== FlowStage.CLIENT_SIGNATURE &&
      this.stage?.id !== FlowStage.PARTNER_SIGNATURE
    ) {
      return true;
    } else {
      return false;
    }
  }

  onAllSignaturesApproved(value: boolean) {
    this.isAllSignaturesApproved = value;
  }

  onEnteredSignDate(value: boolean) {
    this.isEnteredSignDate = value;
  }
}
