import { Environment, EnvironmentType } from '@libs/environment';

const appVersion = '2.1.8';
const apiHost = 'https://tax.deloitte.co.il';
const apiUrl = `${apiHost}/api/v1/`;
const clientId = '5a46c9e9-db5e-47fb-99c5-f4b11be4257c';
const authority = 'https://login.microsoftonline.com/36da45f1-dd2c-4d1f-af13-5abe46b99921';
const redirectUri = 'https://tax.deloitte.co.il';

export const environment: Environment = {
  production: true,
  type: EnvironmentType.Production,
  appVersion,
  userSessionDuration: 1800000, // 30 minutes
  shouldDetectUserSessionActivity: true,
  apiUrl,
  msalDetails: {
    clientId,
    authority,
    redirectUri
  }
};
