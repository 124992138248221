import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { FlowService } from '@digital/app/core/services/flow.service';
import { FlowShellService } from '@digital/app/flow-shell/flow-shell.service';
import { Stage } from '@digital/app/flow/models';
import { EmailerFlow } from '@digital/app/flow/models/emailer-flow.model';
import { ConsultationModalComponent } from '@digital/app/flow/pages/flow/components/_tax/consultation-modal/consultation-modal.component';
import { AlertModalComponent } from '@digital/app/shared/components/alert-modal/alert-modal.component';
import { FlowStage } from '@digital/app/_enums/flow-stages.enum';
import { ExternalUserType } from '@digital/app/_models/client-users';
import { Flow } from '@digital/app/_models/flow.model';
import { AuthenticationService } from '@libs/authentication/authentication.service';
import { UserRole } from '@libs/authentication/models/user.model';
import { UploadFileStageType } from '@libs/enums/upload-file-stage-type.enum';
import { ErrorDialogComponent } from '@libs/views/error-dialog/error-dialog.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BehaviorSubject } from 'rxjs';
import { AlertOpenNotesModalComponent } from '@digital/app/shared/components/alert-open-notes-modal/alert-open-notes-modal.component';
import { FormsService } from '@digital/app/forms/forms.service';

enum WorkOnTaxStage {
  Consultation = 'העברה להתייעצות',
  MangerApprove = 'העברה לאישור מנהל/ת התיק',
  ManagerApproveForExternalUser = 'העבר לצוות הביקורת'
}

enum ManagerApproveStage {
  Consultation = 'העברה להתייעצות',
  Reject = 'דחיה וחזרה לשלב הקודם בתהליך',
  Approve = 'אשר והמשך תהליך',
  BackToQuality = 'החזרה לבקרת איכות',
  BackToManager = 'החזרה לאישור מנהל'
}

enum SignatureVerification {
  Reject = 'דחיה וחזרה לשלב הקודם בתהליך',
  Approve = 'אישור והמשך תהליך'
}

enum SignatureImplementation {
  Reject = 'דחיה וחזרה לשלב עבודה על דוח מס',
  Approve = 'אישור והמשך תהליך'
}

enum ClientOrPartnerSignature {
  Consultation = 'העברה להתייעצות',
  Reject = 'דחיה וחזרה לשלב הקודם בתהליך',
  Approve = 'אשר והמשך תהליך',
  RejectForExternalUser = 'החזר לצוות הביקורת'
}

@Component({
  selector: 'app-dynamic-footer',
  templateUrl: './dynamic-footer.component.html',
  styleUrls: ['./dynamic-footer.component.scss']
})
export class DynamicFooterComponent implements OnInit {
  isLoading$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  @Input() flow: Flow;
  @Input() isConsultation: Stage;
  @Input() currentUserID: number;
  @Input() isUploaderHasFile: boolean;
  @Input() managerID: number;
  @Input() partnerID: number;
  @Input() currentUserEditor: string;
  @Input() isAllSignaturesApproved: boolean = false;
  @Input() isEnteredSignDate: boolean = false;
  public workOnTaxStage: any = WorkOnTaxStage;
  public managerApproveStage: any = ManagerApproveStage;
  public signatureVerification: any = SignatureVerification;
  public clientOrPartnerSignature: any = ClientOrPartnerSignature;
  public signatureImplementation: any = SignatureImplementation;
  isLoading = false;
  footerState = true;

  notesNotClosed: any[] = [];

  FlowStage = FlowStage;

  get isManager() {
    return this.managerID === this.currentUserID;
  }

  get isPartner() {
    return this.partnerID === this.currentUserID;
  }

  get isAdmin() {
    return this._authenticationService.getCurrentUser().role === UserRole.ADMIN;
  }

  get isFormEditor() {
    return this._authenticationService.getCurrentUser().role === UserRole.FORMEDITOR;
  }

  get isExternalUser() {
    return this._authenticationService.getCurrentUser().role === UserRole.EXTERNAL;
  }

  get externalSigner() {
    return this.flow.permissions.externalUsers
      .filter((externalUser) => {
        return (
          (externalUser.role === ExternalUserType.All || externalUser.role === ExternalUserType.Signer) &&
          externalUser.status === 'registered'
        );
      })
      .map((externalUser) => {
        return externalUser.email;
      });
  }

  constructor(
    private _authenticationService: AuthenticationService,
    public _flowService: FlowService,
    private modalService: NgbModal,
    private formsService: FormsService,
    private _flowShellService: FlowShellService,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit() {}

  setNewStage(type: string, specificStage: FlowStage = undefined, StageTwoToFour: boolean = false) {
    try {
      this._flowService.isChangingStage = true;
      let nextFlag = true;
      this.isLoading$.next(true);
      this.isLoading = true;
      this.formsService.onGetNotClosedNotesByFlowId().subscribe((result) => {
        const emailerFlow = new EmailerFlow();
        emailerFlow.companyName = this.flow.company.nameHebrew;
        emailerFlow.flowType = this.flow.type.name;
        emailerFlow.managerMail = this.flow.manager.email;
        emailerFlow.partnerMail = this.flow.partner.email;
        emailerFlow.creatorMail = this.flow.creator.email;
        emailerFlow.externalUsers = this.externalSigner;
        if (
          type === 'next' &&
          result.numberOfRows > 0 &&
          (this.flow.stage.id === 2 || this.flow.stage.id === 3 || this.flow.stage.id === 4)
        ) {
          const modelRef = this.modalService.open(AlertOpenNotesModalComponent, {
            windowClass: 'confirmation-modal',
            centered: true
          });
          modelRef.componentInstance.notesNotClosed = result;
          switch (this.flow.stage.id) {
            case 2:
              if (StageTwoToFour) {
                modelRef.componentInstance.nextStage = 'בקרת איכות';
                modelRef.componentInstance.confirmMessage = `אין להחזיר את התיק לשלב בקרת איכות אם טרם התקבל אישור שותף לערכה השנה
            יש להחזיר לשלב בקרת איכות רק במידה וקיבלתם את התיק מבקרת האיכות וניתן מענה לשאלות והתיקונים שנדרשו.`;
              } else modelRef.componentInstance.nextStage = 'אישור שותף';
              break;
            case 3:
              modelRef.componentInstance.nextStage = 'בקרת איכות';
              break;
            case 4:
              modelRef.componentInstance.nextStage = 'הטמעת חתימות';
              break;
          }
          modelRef.result.then(async (approved) => {
            nextFlag = approved;
            if (nextFlag) {
              // if (this.isExternalUser) {
              //   this._flowService.isChangingStage = true;
              // }

              await this._flowService.setNewStage(this.flow, type, emailerFlow, specificStage);
              await this.needToDisapproveSignatures(type, specificStage, this.flow.stage.id);
              this.isLoading$.next(false);
              this.isLoading$.subscribe((isLoading) => {
                this.isLoading = isLoading;
              });
            }
          });
        } else if (StageTwoToFour && this.flow.stage.id === FlowStage.MANAGER_APPROVAL) {
          this.setNewStageWithWarning('next', FlowStage.QUALITY_CONTROL, FlowStage.MANAGER_APPROVAL);
        } else {
          this._flowService.setNewStage(this.flow, type, emailerFlow, specificStage);
          this.needToDisapproveSignatures(type, specificStage, this.flow.stage.id);
          this.isLoading$.next(false);
          this.isLoading$.subscribe((isLoading) => {
            this.isLoading = isLoading;
          });
        }
      });
    } catch (error) {
      console.log(error.message);
    } finally {
      this.cdr.detectChanges();
    }
  }

  setNewStageWithWarning(type: string, specificStage: FlowStage, fromStage: FlowStage) {
    let confirmMessage = '';
    let title = '';
    const ref = this.modalService.open(AlertModalComponent, { centered: true }).componentInstance;
    let signDateCheck = false;
    let externalUserExists = true;

    if (fromStage === FlowStage.MANAGER_APPROVAL) {
      title = 'החזרה לבקרת איכות';
      confirmMessage = `<b>אין להחזיר את התיק לשלב בקרת איכות אם טרם התקבל אישור שותף לערכה השנה</b> <br>
      יש להחזיר לשלב בקרת איכות רק במידה וקיבלתם את התיק מבקרת האיכות וניתן מענה לשאלות והתיקונים שנדרשו`;
    }

    if (fromStage === FlowStage.PARTNER_APPROVAL) {
      confirmMessage = `<b>עליך לסווג את רמת הסיכון של הדוח - דוח מס ברמת סיכון גבוהה יותר יקבל עדיפות בבדיקת בקרת איכות.</b> <br>
      רמת סיכון - רמת הסיכון לטעות מהותית בדוח נקבעת על פי שיקול דעתו של השותף (יש לקחת בחשבון מקרים חריגים כגון: הלקוח נקט בפוזיציות מס משמעותיות, רולינג או שינוי מבנה מורכב שבוצע והוא בעל השלכה על דוח המס, וכו')`;
      title = 'אשר והמשך תהליך';
      ref.flow = this.flow;
    }

    if (fromStage === FlowStage.QUALITY_CONTROL) {
      title = 'החזרה לאישור מנהל';
      confirmMessage = 'האם אתה בטוח שברצונך להחזיר את התיק לשלב אישור מנהל?';
    }

    if (fromStage === FlowStage.SIGNATURE_IMPLEMENTATION && type === 'next') {
      this.flow = this._flowShellService.flow$.getValue();

      if (this.flow.stageFiles[0]?.stageType === UploadFileStageType.SIGNATURE_IMPLEMENTATION) {
        title = 'העברה ללקוח חותם';
        confirmMessage = 'האם אתה בטוח שברצונך להעביר את התיק לחתימת לקוח?';
        if (this.externalSigner.length === 0) {
          externalUserExists = false;
        }
      } else {
        title = 'העברה לשותף';
        confirmMessage = 'האם אתה בטוח שברצונך להעביר את התיק לחתימת שותף (יש לשים לב שהעלאת קובץ חתום ע״י לקוח)?';
        specificStage = FlowStage.PARTNER_SIGNATURE;
        signDateCheck = true;
      }
    }

    if (
      (fromStage === FlowStage.SIGNATURE_IMPLEMENTATION ||
        fromStage === FlowStage.CLIENT_SIGNATURE ||
        fromStage === FlowStage.PARTNER_SIGNATURE) &&
      type === 'previous'
    ) {
      title = 'העברה לשלב עבודה על דוח מס';
      confirmMessage = 'האם אתה בטוח שברצונך להחזיר את התיק לעבודה על דוח מס?';
    }

    ref.title = title;
    ref.message = confirmMessage;

    ref.positiveAction('אישור', () => {
      if (signDateCheck) {
        this.setNewStageWithVerifications(type, specificStage, fromStage);
      } else if (!externalUserExists) {
        this.setNewStageWithVerifications(type, specificStage, fromStage);
      } else {
        this.setNewStage(type, specificStage);
      }
    });

    ref.negativeAction('ביטול', () => {});
  }

  setNewStageWithVerifications(type: string, specificStage: FlowStage, fromStage: FlowStage) {
    if (fromStage === FlowStage.CLIENT_SIGNATURE || fromStage === FlowStage.PARTNER_SIGNATURE) {
      if (this.isAllSignaturesApproved) {
        this.setNewStage(type, specificStage);
      } else {
        const ref = this.modalService.open(ErrorDialogComponent, { centered: true }).componentInstance;
        ref.title = 'שגיאה במעבר לשלב הבא';
        ref.body = 'יש לאשר את כל החתימות על מנת להעביר לשלב הבא';
      }
    } else if (specificStage === FlowStage.PARTNER_SIGNATURE && fromStage === FlowStage.SIGNATURE_IMPLEMENTATION) {
      if (this.isEnteredSignDate) {
        this.setNewStage(type, specificStage);
      } else {
        setTimeout(() => {
          const ref = this.modalService.open(ErrorDialogComponent, { centered: true }).componentInstance;
          ref.title = 'שגיאה במעבר לשלב הבא';
          ref.body = 'יש להכניס תאריך חתימת לקוח.';
        }, 500);
      }
    } else if (specificStage === FlowStage.CLIENT_SIGNATURE && fromStage === FlowStage.SIGNATURE_IMPLEMENTATION) {
      setTimeout(() => {
        const ref = this.modalService.open(ErrorDialogComponent, { centered: true }).componentInstance;
        ref.title = 'שגיאה במעבר לשלב הבא';
        ref.body = 'לא נמצא משתמש חיצוני עם הרשאות חותם ולכן לא ניתן להעביר את התהליך לשלב הבא';
      }, 500);
    }
  }

  //function that disapprove all signatures
  needToDisapproveSignatures(type: string, specificStage: FlowStage, fromStage: FlowStage) {
    //make this process if the from stage is in Signature stage and returning to previous stage
    if (
      (fromStage === FlowStage.CLIENT_SIGNATURE || fromStage === FlowStage.PARTNER_SIGNATURE) &&
      specificStage < fromStage
    ) {
      this._flowService.disapproveSignatures(this.flow.id);
    }
  }

  openConsultationModal() {
    const modalRef = this.modalService.open(ConsultationModalComponent, {
      windowClass: 'consultation-modal',
      centered: true
    });
    modalRef.componentInstance.flow = this.flow;
  }

  showFooter() {
    const stage = this.flow.stage.id;

    if (stage === FlowStage.CLIENT_SIGNATURE || stage === FlowStage.IRS_CONFIRMATION) {
      this.footerState = this.isUploaderHasFile;
    }

    if (stage === FlowStage.MANAGER_APPROVAL) {
      if (this.isManager || this.isPartner) {
        this.footerState = true;
      }
    }

    if (stage === FlowStage.PARTNER_APPROVAL) {
      if (this.isPartner) {
        this.footerState = true;
      }
    }

    if (stage === FlowStage.PARTNER_SIGNATURE) {
      if (!this.isPartner) {
        this.footerState = false;
      }
    }

    if (stage === FlowStage.QUALITY_CONTROL) {
      if (!this.isAdmin || !this.isFormEditor) {
        this.footerState = false;
      }
    }

    if (this.isAdmin || this.isFormEditor) {
      this.footerState = true;
    }

    if (
      this.flow.permissions.externalUsers.some((user) => user.id === this.currentUserID) &&
      this.flow.stage.id === FlowStage.CLIENT_SIGNATURE
    ) {
      this.footerState = true;
    }

    return this.footerState;
  }
}
